/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Button, Text, Image, ColumnCover, ColumnDiv, YouTube, ContactForm } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout >
        <SiteHeader set="" currentLanguage={0} />

        <Column className="css-1iwcpu3 --full pb--30 pt--50" name={"introduction"} fullscreen={true} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/7952/8b8f27ff3b5e44e49a8b9aed437bdd57_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/7952/8b8f27ff3b5e44e49a8b9aed437bdd57_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/7952/8b8f27ff3b5e44e49a8b9aed437bdd57_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/7952/8b8f27ff3b5e44e49a8b9aed437bdd57_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/7952/8b8f27ff3b5e44e49a8b9aed437bdd57_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/7952/8b8f27ff3b5e44e49a8b9aed437bdd57_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/7952/8b8f27ff3b5e44e49a8b9aed437bdd57_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/7952/8b8f27ff3b5e44e49a8b9aed437bdd57_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Title className="title-box title-box--shadow2 title-box--center fs--220" content={"<span style=\"color: var(--white);\">Vlaďka &amp;Roman</span>"}>
              </Title>

              <Button className="btn-box btn-box--hvr2 btn-box--cbtn1 btn-box--cColor1 btn-box--shadow2 w--300 mt--30" href={"/#formular"} content={"<span style=\"color: var(--color-supplementary);\">Potvrzení účasti</span>"} use={"page"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--left pb--60 pt--80" style={{"backgroundColor":"var(--color-supplementary)"}} name={"information"}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--86 w--300 ls--0" style={{"maxWidth":880}} content={"<span style=\"color: var(--color-dominant)\">S radostí Vás zveme na náš<br>svatební den<br></span>"}>
              </Title>

              <Text className="text-box fs--20 lh--2 mt--25" style={{"maxWidth":880}} content={"<span style=\"color: var(--white);\"><span style=\"font-weight: bold; text-decoration-line: underline;\">Vše se odehraje:</span><br>Lidový dům Žabčice<br>U Rybníka 23, 664 63 Žabčice</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--80" style={{"backgroundColor":"var(--color-supplementary)"}} name={"information-2"} layout={"l30"}>
          
          <ColumnWrap className="column__flex --center el--2 mt--0 flex--center" columns={"2"}>
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/37814/f9cf153c9dc34f2ca439c28759aef7b0_s=350x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/37814/f9cf153c9dc34f2ca439c28759aef7b0_s=350x_.jpg 350w"}>
              </Image>

              <ColumnCover >
                
                <ColumnWrapper className="--center flex--center">
                  
                  <ColumnDiv >
                    
                    <Title className="title-box title-box--shadow2 fs--154" style={{"maxWidth":1000}} content={"<span style=\"color: white\">21. 9. 2024</span>"}>
                    </Title>

                    <Title className="title-box title-box--shadow2 fs--62 mt--02" content={"<span style=\"color: white\">14:00</span>"}>
                    </Title>

                  </ColumnDiv>

                </ColumnWrapper>

              </ColumnCover>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/37814/ed90eb683c2e4945b3c3aa8a00b32f0d_s=350x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/37814/ed90eb683c2e4945b3c3aa8a00b32f0d_s=350x_.jpg 350w"}>
              </Image>

              <ColumnCover >
                
                <ColumnWrapper className="--center flex--center">
                  
                  <ColumnDiv >
                    
                    <Title className="title-box title-box--shadow2 fs--43" content={"<span style=\"color: white\">Lidový dům</span>"}>
                    </Title>

                    <Title className="title-box title-box--shadow2 fs--128 mt--02 pl--0 pr--0" content={"<span style=\"color: white\">Žabčice</span>"}>
                    </Title>

                  </ColumnDiv>

                </ColumnWrapper>

              </ColumnCover>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--left pb--50 pt--80" name={"program"}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--86" style={{"maxWidth":880}} content={"Rozvrh"}>
              </Title>

              <Text className="text-box fs--20 swpf--uppercase lh--2 mt--25" style={{"maxWidth":880}} content={"<span style=\"color: rgb(40, 50, 70);\">Zde naleznete harmonogram svatby. Předpokládáme však, že některé časy nebudou přesně dodrženy, však to znáte. Přesto Vás prosíme přijďte včas a s dobrou náladou!</span><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--left pb--80 pt--80" name={"program-3"}>
          
          <ColumnWrap className="column__flex --center el--4 flex--top" style={{"maxWidth":1300}} columns={"4"}>
            
            <ColumnWrapper className="pb--20 pl--20 pr--20">
              
              <Title className="title-box fs--72" content={"13:00"}>
              </Title>

              <Text className="text-box ls--01 lh--2 mt--10" content={"Příjezd hostů"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="pb--20 pl--20 pr--20">
              
              <Title className="title-box fs--72" content={"<span style=\"color: rgb(168, 145, 11);\">14:00</span>"}>
              </Title>

              <Text className="text-box ls--01 lh--2 mt--10" content={"Začátek obřadu"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="pb--20 pl--20 pr--20">
              
              <Title className="title-box fs--72" content={"15:00"}>
              </Title>

              <Text className="text-box ls--01 lh--2 mt--10" content={"Přesun do sálu na svatební zábavu"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="pb--20 pl--20 pr--20">
              
              <Title className="title-box fs--72" content={"16:00"}>
              </Title>

              <Text className="text-box ls--01 lh--2 mt--10" content={"Raut a občerstvení"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="pb--20 pl--20 pr--20">
              
              <Title className="title-box fs--72" content={"17:00"}>
              </Title>

              <Text className="text-box ls--01 lh--2 mt--10" content={"První tanec novomanželů"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="pb--20 pl--20 pr--20">
              
              <Title className="title-box fs--72" content={"18:00"}>
              </Title>

              <Text className="text-box ls--01 lh--2 mt--10" content={"Volná zábava / svatební aktivity<br>(možnost obléci se civilněji)<br><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--center pb--20 pl--20 pr--20">
              
              <Title className="title-box fs--72" style={{"maxWidth":340,"paddingLeft":0,"paddingRight":0}} content={"20:00"}>
              </Title>

              <Text className="text-box ls--01 lh--2 mt--10" content={"Večerní zábava a tanec"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="pb--20 pl--20" style={{"paddingRight":2}}>
              
              <Title className="title-box fs--72" style={{"maxWidth":300,"paddingLeft":0,"paddingRight":0}} content={"22:00"}>
              </Title>

              <Text className="text-box ls--01 lh--2 mt--10" content={"<span style=\"text-decoration-line: underline;\">Živá hudba – po ní případně Jam Session<br>(Kdo chce a může, vemte si hudební nástroj)</span><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"lpl7rt9xjp"}>
          
          <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"Kování prstenů"}>
              </Title>

              <Text className="text-box swpf--uppercase" content={"V tento den jsme si pro sebe ukovali svatební prsteny. Ve videu je ukázka z onoho dne, jak jsme se zaučovali v kovárně a navzájem jsme si vytvořili pro toho druhého naše symboly lásky."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <YouTube embedId={"86ZELwEXvbw"}>
              </YouTube>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--50 pt--80" style={{"backgroundColor":"var(--color-supplementary)"}} name={"items"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--86" style={{"maxWidth":880}} content={"<span style=\"color: var(--color-dominant)\">Dary</span>"}>
              </Title>

              <Text className="text-box fs--20 swpf--uppercase lh--2 mt--25" style={{"maxWidth":880}} content={"<span style=\"color: var(--color-dominant)\">Dary nejsou důležité. Ze všeho nejvíce chceme sdílet náš speciální den s Vámi! Pokud byste trvali na daru, budeme velice vděční za peněžní dar nebo se můžete inspirovat seznamem níže. Berte nás však s rezervou, jsme vtipálci...<br></span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center pb--60 pt--60" style={{"backgroundColor":"var(--color-supplementary)"}} name={"o63ga953aoa"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--center">
              
              <Text className="text-box fs--18" style={{"maxWidth":1000}} content={"<span style=\"color: var(--white);\">Dělátko hnědého moku (ideální v případě, že nás rádi navštívíte a jste milovníkem kávy)<br><br>Přesouvátko z bodu A do bodu B (jakákoliv značka, hlavně když je vozítko pojizdné až do smrti)<br><br>Pečítko na dobrůtky (na zahradní grilovačky a oslavy)<br><br>Zděnné potápítko (na letní čvachtání u vody)<br><br>Moře lásky (koření života)<br><br>...</span><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--30" style={{"backgroundColor":"var(--color-supplementary)"}} name={"items-2"}>
          
          <ColumnWrap className="column__flex --center el--1 mt--0 flex--center" columns={"1"} fullscreen={false}>
          </ColumnWrap>

        </Column>


        <Column className="pb--80 pt--80" name={"formular"}>
          
          <ColumnWrap className="column__flex --center el--1 pt--12" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--86" style={{"maxWidth":1080}} content={"Došla Vám pozvánka?&nbsp;"}>
              </Title>

              <Text className="text-box fs--20 swpf--uppercase lh--2 mt--25" style={{"maxWidth":880}} content={"Pomozte nám s organizací a prosím vyplňte tento formulář"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="js-anim  --anim5 pb--80" anim={"5"} name={"program-2"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim5 --shadow2 pb--20" style={{"marginTop":0}} anim={"5"}>
              
              <ContactForm className="--shape2 --shape2 ff--2 fs--20 w--600 ls--02 lh--14" style={{"maxWidth":1050}} action={"/contact"} fields={[{"name":"Celé jméno","type":"text","required":true,"placeholder":"Vyplňte prosím celé své jméno","id":"4plxx94ysz6j"},{"name":"Telefonní číslo","type":"text","required":false,"placeholder":"Telefonní číslo","id":"vpcz8nuzro4k"},{"name":"Beru druhou polovičku","type":"text","required":true,"placeholder":"Ano/Ne","id":"ha57lybisft8"},{"name":"Budeme brát děti (prosíme věk)","type":"text","required":true,"placeholder":"Ano/Ne","id":"yidyl2ollzxc"},{"name":"Alergie","type":"text","required":true,"placeholder":"Laktóza, lepek či jiná omezení","id":"tinzqqbwi5"},{"name":"Přespání","type":"text","placeholder":"Potřebuji přespat / Přivezu si spacák","id":"649sx9yql5bj"},{"name":"Odvoz","type":"text","placeholder":"Po vlastní ose, či potřebuji odvoz","id":"2uvy9vlh6xe0"},{"name":"Odeslat","type":"submit","align":"center","id":"sd9c5figo17z"}]} layout={"l2"}>
              </ContactForm>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"photogallery"} layout={"l12"}>
          
          <ColumnWrap className="column__flex --center el--3 --full" columns={"3"} fullscreen={true}>
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/37814/aec32bf2b6f64b6c933d81e7b0df7775_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/37814/aec32bf2b6f64b6c933d81e7b0df7775_s=350x_.jpg 350w, https://cdn.swbpg.com/t/37814/aec32bf2b6f64b6c933d81e7b0df7775_s=660x_.jpg 660w, https://cdn.swbpg.com/t/37814/aec32bf2b6f64b6c933d81e7b0df7775_s=860x_.jpg 860w, https://cdn.swbpg.com/t/37814/aec32bf2b6f64b6c933d81e7b0df7775_s=1400x_.jpg 1400w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/37814/0e4ef59591f74515874f803696795f30_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/37814/0e4ef59591f74515874f803696795f30_s=350x_.jpg 350w, https://cdn.swbpg.com/t/37814/0e4ef59591f74515874f803696795f30_s=660x_.jpg 660w, https://cdn.swbpg.com/t/37814/0e4ef59591f74515874f803696795f30_s=860x_.jpg 860w, https://cdn.swbpg.com/t/37814/0e4ef59591f74515874f803696795f30_s=1400x_.jpg 1400w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/37814/3c722e69b8244c9aa77918acf7544d06_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/37814/3c722e69b8244c9aa77918acf7544d06_s=350x_.jpg 350w, https://cdn.swbpg.com/t/37814/3c722e69b8244c9aa77918acf7544d06_s=660x_.jpg 660w, https://cdn.swbpg.com/t/37814/3c722e69b8244c9aa77918acf7544d06_s=860x_.jpg 860w, https://cdn.swbpg.com/t/37814/3c722e69b8244c9aa77918acf7544d06_s=1400x_.jpg 1400w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--left pb--80 pt--80" style={{"backgroundColor":"var(--color-supplementary)"}} name={"footer"}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--86" style={{"maxWidth":""}} content={"<span style=\"color: var(--color-dominant)\">Těšíme se na Vás!</span>"}>
              </Title>

              <Text className="text-box lh--2 mt--12" style={{"maxWidth":880}} content={"<span style=\"color: var(--color-dominant)\"><br></span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}