export default {
  "": {
    "id": 1889,
    "temp_key": "",
    "site_id": 29782,
    "name": "",
    "items": [
      {
        "id": "sh8yuxzf8lg8",
        "url": null,
        "use": "page",
        "name": "Pozvánka",
        "page": "/",
        "blank": null,
        "section": "information",
        "document": null,
        "elements": []
      },
      {
        "id": "1o1w6jxd3dyw",
        "url": null,
        "use": "page",
        "name": "Místo & čas",
        "page": "/",
        "blank": null,
        "section": "information-2",
        "document": null,
        "elements": []
      },
      {
        "id": "p21qvxky1jfn",
        "url": null,
        "use": "page",
        "name": "Rozvrh",
        "page": "/",
        "blank": null,
        "section": "program",
        "document": null,
        "elements": []
      },
      {
        "id": "bhm4it7k4mk8",
        "url": null,
        "use": "page",
        "name": "Další informace",
        "page": "/",
        "blank": null,
        "section": "items",
        "document": null,
        "elements": []
      },
      {
        "id": "l56g5s3bfsfb",
        "use": "page",
        "name": "Prosím vyplnit",
        "page": "/",
        "section": "formular"
      }
    ]
  }
}